/* :: 9.0 Blog Area CSS */
.single-blog-post {
  position: relative;
  z-index: 1;
  overflow: hidden;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}

.single-blog-post.style-1 .blog-thumbnail {
  position: relative;
  z-index: 1;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}

.single-blog-post.style-1 .blog-thumbnail img {
  width: 100%;
  -webkit-transition-duration: 800ms;
  transition-duration: 800ms;
}

.single-blog-post.style-1 .blog-thumbnail.bg-overlay::after {
  z-index: 5;
}

.single-blog-post.style-1 .blog-content {
  position: absolute;
  bottom: 30px;
  left: 30px;
  z-index: 50;
}

.single-blog-post.style-1 .blog-content .post-date {
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
  display: block;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}

.single-blog-post.style-1 .blog-content .post-title {
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 0;
  color: #ffffff;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-blog-post.style-1 .blog-content .post-title {
    font-size: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-blog-post.style-1 .blog-content .post-title {
    font-size: 16px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-blog-post.style-1 .blog-content .post-title {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .single-blog-post.style-1 .blog-content .post-title {
    font-size: 16px;
  }
}

.single-blog-post.style-1 .blog-content .post-title:hover,
.single-blog-post.style-1 .blog-content .post-title:focus {
  color: #8a8a8a;
}

.single-blog-post.style-1:hover .blog-thumbnail img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.single-blog-post.style-2 .blog-thumbnail {
  margin-bottom: 20px;
}

.single-blog-post.style-2 .blog-thumbnail img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.single-blog-post.style-2 .blog-content {
  position: relative;
  z-index: 1;
}

.single-blog-post.style-2 .blog-content .post-date {
  font-size: 12px;
  color: #636363;
  margin-bottom: 10px;
}

.single-blog-post.style-2 .blog-content .post-title {
  font-family: 'Playfair Display', serif !important;
  display: block;
  font-weight: 600;
  font-size: 22px !important;
  margin-bottom: 10px;
  color: #212529;
}

.single-blog-post.style-2 .blog-content .post-title:hover,
.single-blog-post.style-2 .blog-content .post-title:focus {
  color: #8a8a8a;
  text-decoration: none;
}

.single-blog-post.style-2 .blog-content .post-author {
  display: block;
  font-size: 12px !important;
  color: #8a8a8a;
  font-weight: 500;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}

.single-blog-post.style-2 .blog-content .post-author:hover,
.single-blog-post.style-2 .blog-content .post-author:focus {
  color: #8a8a8a;
}

.single-blog-post.style-2:hover {
  -webkit-transform: translateY(-15px);
  transform: translateY(-15px);
}

.single-blog-post.style-2:hover .blog-content .post-title {
  color: #8a8a8a;
}

.single-blog-post.style-3 .blog-thumbnail {
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}

.single-blog-post.style-3 .blog-thumbnail img {
  width: 100%;
}

.single-blog-post.style-3 .blog-thumbnail .video-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 63px;
  height: 63px;
  background-color: #ef1b48;
  color: #ffffff;
  font-size: 24px;
  line-height: 63px;
  display: block;
  border-radius: 50%;
  text-align: center;
  margin: 0 auto 20px;
  z-index: 50;
}

.single-blog-post.style-3 .blog-thumbnail .video-btn:hover {
  background-color: #000000;
  color: #ffffff;
}

.single-blog-post.style-3 .blog-content {
  position: relative;
  z-index: 1;
}

.single-blog-post.style-3 .blog-content .post-date {
  font-size: 12px;
  color: #ffffff;
  margin-bottom: 10px;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}

.single-blog-post.style-3 .blog-content .post-title {
  display: block;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 10px;
  color: #ffffff;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  line-height: 1.3;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-blog-post.style-3 .blog-content .post-title {
    font-size: 18px;
  }
}

.single-blog-post.style-3 .blog-content .post-title:hover,
.single-blog-post.style-3 .blog-content .post-title:focus {
  color: #ef1b48;
}

.single-blog-post.style-3 .blog-content .post-author {
  display: block;
  font-size: 12px;
  color: #ffffff;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  font-weight: 500;
}

.single-blog-post.style-3 .blog-content .post-author:hover,
.single-blog-post.style-3 .blog-content .post-author:focus {
  color: #ef1b48;
}

.single-blog-post.style-3:hover .blog-content .post-title {
  color: #ef1b48;
}

.single-blog-post.style-4 .blog-thumbnail {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 120px;
  flex: 0 0 120px;
  max-width: 120px;
  width: 120px;
  margin-right: 15px;
}

.single-blog-post.style-4 .blog-thumbnail img {
  width: 100%;
}

.single-blog-post.style-4 .blog-content {
  position: relative;
  z-index: 1;
}

.single-blog-post.style-4 .blog-content .post-date {
  font-size: 12px;
  color: #636363;
  margin-bottom: 5px;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}

.single-blog-post.style-4 .blog-content .post-title {
  font-family: 'Playfair Display', serif !important;
  display: block;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0;
  color: #232323;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}

.single-blog-post.style-4 .blog-content .post-title:hover,
.single-blog-post.style-4 .blog-content .post-title:focus {
  font-weight: 600;
  color: #8a8a8a;
}

.single-blog-post.style-4:hover .blog-content .post-title {
  color: #8a8a8a;
  text-decoration: none;
}

.single-blog-post.style-5 .blog-thumbnail {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 45%;
  flex: 0 0 45%;
  max-width: 45%;
  width: 45%;
  margin-right: 30px;
}

.single-blog-post.style-5 .blog-thumbnail img {
  width: 100%;
}

@media only screen and (max-width: 767px) {
   .intro-news-filter {
    margin-top: 40px;
   }
  .single-blog-post.style-5 .blog-thumbnail {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }
}

.single-blog-post.style-5 .blog-content {
  position: relative;
  z-index: 1;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(55% - 30px);
  flex: 0 0 calc(55% - 30px);
  max-width: calc(55% - 30px);
  width: calc(55% - 30px);
}

@media only screen and (max-width: 767px) {
  .single-blog-post.style-5 .blog-content {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
  }
}

.single-blog-post.style-5 .blog-content .post-date {
  font-size: 12px;
  color: #636363;
  margin-bottom: 10px;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}

.single-blog-post.style-5 .blog-content .post-title {
  display: block;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 10px;
  color: #232323;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-blog-post.style-5 .blog-content .post-title {
    font-size: 18px;
  }
}

.single-blog-post.style-5 .blog-content .post-title:hover,
.single-blog-post.style-5 .blog-content .post-title:focus {
  font-weight: 600;
  color: #ef1b48;
}

.single-blog-post.style-5 .blog-content .post-author {
  font-size: 12px;
  color: #636363;
  margin-bottom: 30px;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  display: block;
}

.single-blog-post.style-5:hover .blog-content .post-title {
  color: #ef1b48;
}

.single-blog-post.style-6 .blog-thumbnail {
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}

.single-blog-post.style-6 .blog-thumbnail img {
  width: 100%;
}

.single-blog-post.style-6 .blog-thumbnail .video-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 44px;
  height: 44px;
  background-color: #ef1b48;
  color: #ffffff;
  font-size: 18px;
  line-height: 44px;
  display: block;
  border-radius: 50%;
  text-align: center;
  margin: 0 auto 20px;
  z-index: 50;
}

.single-blog-post.style-6 .blog-thumbnail .video-btn:hover {
  background-color: #000000;
  color: #ffffff;
}

.single-blog-post.style-6 .blog-content {
  position: relative;
  z-index: 1;
}

.single-blog-post.style-6 .blog-content .post-date {
  font-size: 12px;
  color: #a2a2a2;
  margin-bottom: 10px;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}

.single-blog-post.style-6 .blog-content .post-title {
  display: block;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0;
  color: #232323;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  line-height: 1.3;
}

.single-blog-post.style-6 .blog-content .post-title:hover,
.single-blog-post.style-6 .blog-content .post-title:focus {
  font-weight: 600;
  color: #ef1b48;
}

.single-blog-post.style-6:hover .blog-content .post-title {
  color: #ef1b48;
}

.intro-news-filter {
  z-index: 1;
  margin-bottom: 30px;
}

.intro-news-filter h6 {
  font-size: 16px;
  margin-bottom: 0;
  line-height: 32px;
  color: #404040;
  text-transform: uppercase;
  padding-left: 15px;
}
.intro-news-filter .nav-tabs {
  border-bottom: none;
  justify-content: flex-end;
  align-items: center;
}
.intro-news-filter .nav-tabs .nav-link,  .intro-news-filter h6{
  border: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  display: block;
  padding: 0 5px;
  font-size: 14px;
  color: #8a8a8a;
  text-transform: uppercase;
  line-height: 32px;
  background-color: transparent;
}

.intro-news-filter .nav-tabs .nav-link {
  line-height: 62px;
}

.intro-news-filter .nav-tabs .nav-link.active {
  color: #000;
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .intro-news-filter .nav-tabs .nav-link {
    padding: 0 8px;
  }
}

#ledare_page h1 {
  font-family: 'Playfair Display';
  font-size: 30px !important;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 37px;
  padding-bottom: 15px;
}
.ledare_datum p {
  padding-top: 0px;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-variant: normal;
  font-weight: 600;
  letter-spacing: 0px;
}
.ledare_ingress {
  position: relative;
  width: 100%;
}
.ledare_ingress p {
  padding-top: 10px;
  text-transform: uppercase;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  line-height: 19px;
  font-style: normal;
  font-variant: normal;
  font-weight: 300;
}
.ledare_ingress p span {
  font-weight: 600;
}
.ledare_linti p {
  padding-top: 10px;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-variant: normal;
  line-height: 71px;
  letter-spacing: 0px;
}

#ledare_page .newscontent img {
  max-width: 100%;
}
#ledare_page .newscontent p {
  font-size: 14px;
  font-weight: 400 !important;
}

@media (min-width: 576px) {
  #ledare_page h1 {
    font-size: 30px !important;
    line-height: 37px;
    padding-bottom: 15px;
  }
  .ledare_datum p {
    padding-top: 0px;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0px;
  }
  .ledare_ingress {
    position: relative;
  }
  .ledare_ingress p {
    padding-top: 10px;
    font-size: 14px;
    line-height: 19px;
  }
  .ledare_linti p {
    padding-top: 10px;
    font-size: 15px;
    line-height: 71px;
    letter-spacing: 0px;
  }
}
@media (min-width: 768px) {
  .intro-news-filter .nav-tabs .nav-link, .intro-news-filter h6 {
    font-size: 16px;
    padding: 0 15px;
  }

  #ledare_page h1 {
    font-size: 20px !important;
    line-height: 22px;
    padding-bottom: 0px;
  }
  .ledare_datum p {
    padding-top: 0px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0px;
  }
  .ledare_ingress {
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .ledare_ingress p {
    padding-top: 10px;
    font-size: 10px;
    line-height: 12px;
  }
  .ledare_linti p {
    padding-top: 0px;
    font-size: 15px;
    line-height: 5px;
    letter-spacing: 0px;
  }
}
@media (min-width: 992px) {
  #ledare_page h1 {
    font-size: 30px !important;
    line-height: 35px;
    padding-bottom: 10px;
  }
  .ledare_datum p {
    padding-top: 0px;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0px;
  }
  .ledare_ingress {
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .ledare_ingress p {
    padding-top: 12px;
    font-size: 14px;
    line-height: 19px;
  }
  .ledare_linti p {
    padding-top: 0px;
    font-size: 15px;
    line-height: 71px;
    letter-spacing: 0px;
  }
}
@media (min-width: 1200px) {
  #ledare_page h1 {
    font-size: 40px !important;
    line-height: 50px;
    padding-bottom: 25px;
  }
  .ledare_datum p {
    padding-top: 0px;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0px;
  }
  .ledare_ingress {
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .ledare_ingress p {
    padding-top: 12px;
    font-size: 14px;
    line-height: 19px;
  }
  .ledare_linti p {
    padding-top: 0px;
    font-size: 15px;
    line-height: 71px;
    letter-spacing: 0px;
  }
}
@media (min-width: 1700px) {
  #ledare_page h1 {
    font-size: 64px !important;
    line-height: 76px;
    padding-bottom: 56px;
  }
  .ledare_datum p {
    padding-top: 0px;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0px;
  }
  .ledare_ingress {
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .ledare_ingress p {
    padding-top: 15px;
    font-size: 18px;
    line-height: 25px;
  }
  .ledare_linti p {
    padding-top: 10px;
    font-size: 15px;
    line-height: 71px;
    letter-spacing: 0px;
  }
}

@media (min-width: 768px) {
  #ledare_page .container {
    max-width: 720px !important;
  }
}
@media (min-width: 576px) {
  #ledare_page .container {
    max-width: 540px !important;
  }
}
@media (min-width: 992px) {
  #ledare_page .container {
    max-width: 960px !important;
  }
}
@media (min-width: 1200px) {
  #ledare_page .container {
    max-width: 1140px !important;
  }
}

@media (min-width: 1700px) {
  #ledare_page .container {
    max-width: 1640px !important;
  }
}

#ledare_page hr {
  border: 1px solid #232323;
  margin-bottom: 0px;
}
#intervju_page .ledare_linti,
#intervju_page .ledare_ingress,
#intervju_page .ledare_datum,
#intervju_page h1 {
  color: #fff;
}

/*css for slider only */
#ledare_page .hover-me::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  text-align: center;
}

#ledare_page .hover-me:hover:before {
  background-color: rgba(0, 0, 0, 0.9);
  font-family: 'Playfair Display', serif !important;
  font-weight: 600;
  font-size: 28px;
  content: 'READ MORE';
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: 0.7s;
}
#ledare_page .cards-wrapper {
  display: flex;
  justify-content: center;
}
#ledare_page .card img {
  max-width: 100%;
  max-height: 100%;
}
#ledare_page .card {
  margin: 0 0.5em;
  box-shadow: 2px 6px 8px 0 rgba(22, 22, 26, 0.18);
  border: none;
  border-radius: 0;
  width: 100%;
}
#ledare_page .carousel-inner {
  padding: 4em;
  max-height: 480px;
}
#ledare_page .carousel-control-prev,
#ledare_page .carousel-control-next {
  background-color: #daa520;
  width: 5vh;
  height: 5vh;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}
#ledare_page .carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  /* background-color: #daa520; */
  text-decoration: none;
  outline: 0;
  opacity: 1;
}

#ledare_page .card-title {
  font-size: 12px;
  color: #636363;
  margin-bottom: 10px;
}
#ledare_page .card-text {
  font-family: 'Playfair Display', serif !important;
  display: block;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 10px;
  color: #212529;
  text-decoration: none;
}
#ledare_page .card a:hover {
  text-decoration: none !important;
}
#ledare_page .card-body {
  min-height: 150px;
}

#ledare_page .borderliner {
  border-bottom: #c7c7c7 solid 1px;
  margin-top: 30px;
  padding-bottom: 50px;
}

@media (min-width: 768px) {
  #ledare_page .carousel-inner {
    padding: 4em;
    max-height: 500px;
  }
  #ledare_page .card img {
    height: 11em;
    width: 100%;
    object-fit: cover;
  }
  #ledare_page .card-body {
    min-height: 150px;
  }
}
@media (min-width: 992px) {
  #ledare_page .carousel-inner {
    padding: 4em;
    max-height: 480px;
  }
  #ledare_page .card-body {
    min-height: 200px;
  }
  #ledare_page .card {
    margin: 0 0.5em;
    box-shadow: 2px 6px 8px 0 rgba(22, 22, 26, 0.18);
    border: none;
    border-radius: 0;
    width: 100%;
  }
}
@media (min-width: 1200px) {
  #ledare_page .carousel-inner {
    padding: 4em;
    max-height: 430px;
  }
  #ledare_page .card-body {
    min-height: 140px;
  }
  #ledare_page .card {
    margin: 0 0.5em;
    box-shadow: 2px 6px 8px 0 rgba(22, 22, 26, 0.18);
    border: none;
    border-radius: 0;
    width: 100%;
  }
}
@media (min-width: 1700px) {
  #ledare_page .carousel-inner {
    padding: 4em;
    max-height: 520px;
  }
  #ledare_page .card-body {
    min-height: 140px;
  }
  #ledare_page .card {
    margin: 0 1.5em;
    box-shadow: 2px 6px 8px 0 rgba(22, 22, 26, 0.18);
    border: none;
    border-radius: 0;
    width: 100%;
  }
}
/*css for slider only */
